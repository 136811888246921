export const keys = {
  CATEGORY: "CATEGORY",
  INGREDIENTS: "INGREDIENTS",
  HEALTH: "HEALTH",
  ALLERGY: "ALLERGY",
  SEARCH: "SEARCH",
  PRINT_VERSION: "PRINT_VERSION",
  WEBSITE: "WEBSITE",
  PROTEIN: "protein",
  GRAIN: "grain",
  VEGETABLE: "vegetable",
  FRUIT: "fruit",
  DAIRY: "dairy",
  FOOD: "food",
  ALCOHOLIC_BEVERAGE: "alcoholic beverage",
  NON_ALCOHOLIC_BEVERAGE: "non-alcoholic beverage",
  OTHER: "other",
};

const translations = {
  en: {
    [keys.CATEGORY]: "category",
    [keys.INGREDIENTS]: "ingredients",
    [keys.HEALTH]: "health",
    [keys.ALLERGY]: "allergy",
    [keys.SEARCH]: "search",
    [keys.PRINT_VERSION]: "print version",
    [keys.WEBSITE]: "website",
    [keys.PROTEIN]: "protein",
    [keys.GRAIN]: "grain",
    [keys.VEGETABLE]: "vegetable",
    [keys.FRUIT]: "fruit",
    [keys.DAIRY]: "dairy",
    [keys.FOOD]: "food",
    [keys.ALCOHOLIC_BEVERAGE]: "alcoholic beverage",
    [keys.NON_ALCOHOLIC_BEVERAGE]: "non-alcoholic beverage",
    [keys.OTHER]: "other",
  },
  de: {
    [keys.CATEGORY]: "Kategorie",
    [keys.INGREDIENTS]: "Zutaten",
    [keys.HEALTH]: "Gesundheit",
    [keys.ALLERGY]: "Allergie",
    [keys.SEARCH]: "Suche",
    [keys.PRINT_VERSION]: "Druckversion",
    [keys.WEBSITE]: "Webseite",
    [keys.PROTEIN]: "Protein",
    [keys.GRAIN]: "Getreide",
    [keys.VEGETABLE]: "Gemüse",
    [keys.FRUIT]: "Frucht",
    [keys.DAIRY]: "Milchprodukt",
    [keys.FOOD]: "Essen",
    [keys.ALCOHOLIC_BEVERAGE]: "alkoholisches Getränk",
    [keys.NON_ALCOHOLIC_BEVERAGE]: "alkoholfreies Getränk",
    [keys.OTHER]: "sonstiges",
  },
  fr: {
    [keys.CATEGORY]: "catégorie",
    [keys.INGREDIENTS]: "ingrédients",
    [keys.HEALTH]: "santé",
    [keys.ALLERGY]: "allergie",
    [keys.SEARCH]: "recherche",
    [keys.PRINT_VERSION]: "version imprimable",
    [keys.WEBSITE]: "site web",
    [keys.PROTEIN]: "protéine",
    [keys.GRAIN]: "céréale",
    [keys.VEGETABLE]: "légume",
    [keys.FRUIT]: "fruit",
    [keys.DAIRY]: "produit laitier",
    [keys.FOOD]: "nourriture",
    [keys.ALCOHOLIC_BEVERAGE]: "boisson alcoolisée",
    [keys.NON_ALCOHOLIC_BEVERAGE]: "boisson non alcoolisée",
    [keys.OTHER]: "autre",
  },
  es: {
    [keys.CATEGORY]: "categoría",
    [keys.INGREDIENTS]: "ingredientes",
    [keys.HEALTH]: "salud",
    [keys.ALLERGY]: "alergia",
    [keys.SEARCH]: "búsqueda",
    [keys.PRINT_VERSION]: "versión imprimible",
    [keys.WEBSITE]: "sitio web",
    [keys.PROTEIN]: "proteína",
    [keys.GRAIN]: "grano",
    [keys.VEGETABLE]: "vegetal",
    [keys.FRUIT]: "fruta",
    [keys.DAIRY]: "lácteo",
    [keys.FOOD]: "comida",
    [keys.ALCOHOLIC_BEVERAGE]: "bebida alcohólica",
    [keys.NON_ALCOHOLIC_BEVERAGE]: "bebida no alcohólica",
    [keys.OTHER]: "otro",
  },
  ru: {
    [keys.CATEGORY]: "категория",
    [keys.INGREDIENTS]: "ингредиенты",
    [keys.HEALTH]: "здоровье",
    [keys.ALLERGY]: "аллергия",
    [keys.SEARCH]: "поиск",
    [keys.PRINT_VERSION]: "версия для печати",
    [keys.WEBSITE]: "веб-сайт",
    [keys.PROTEIN]: "белок",
    [keys.GRAIN]: "зерно",
    [keys.VEGETABLE]: "овощ",
    [keys.FRUIT]: "фрукт",
    [keys.DAIRY]: "молочный продукт",
    [keys.FOOD]: "еда",
    [keys.ALCOHOLIC_BEVERAGE]: "алкогольный напиток",
    [keys.NON_ALCOHOLIC_BEVERAGE]: "безалкогольный напиток",
    [keys.OTHER]: "другое",
  },
  ko: {
    [keys.CATEGORY]: "카테고리",
    [keys.INGREDIENTS]: "재료",
    [keys.HEALTH]: "건강",
    [keys.ALLERGY]: "알레르기",
    [keys.SEARCH]: "검색",
    [keys.PRINT_VERSION]: "인쇄 버전",
    [keys.WEBSITE]: "웹사이트",
    [keys.PROTEIN]: "단백질",
    [keys.GRAIN]: "곡물",
    [keys.VEGETABLE]: "채소",
    [keys.FRUIT]: "과일",
    [keys.DAIRY]: "유제품",
    [keys.FOOD]: "음식",
    [keys.ALCOHOLIC_BEVERAGE]: "알코올 음료",
    [keys.NON_ALCOHOLIC_BEVERAGE]: "비알코올 음료",
    [keys.OTHER]: "기타",
  },
  zh: {
    [keys.CATEGORY]: "类别",
    [keys.INGREDIENTS]: "成分",
    [keys.HEALTH]: "健康",
    [keys.ALLERGY]: "过敏",
    [keys.SEARCH]: "搜索",
    [keys.PRINT_VERSION]: "打印版本",
    [keys.WEBSITE]: "网站",
    [keys.PROTEIN]: "蛋白质",
    [keys.GRAIN]: "谷物",
    [keys.VEGETABLE]: "蔬菜",
    [keys.FRUIT]: "水果",
    [keys.DAIRY]: "乳制品",
    [keys.FOOD]: "食物",
    [keys.ALCOHOLIC_BEVERAGE]: "酒精饮料",
    [keys.NON_ALCOHOLIC_BEVERAGE]: "非酒精饮料",
    [keys.OTHER]: "其他",
  },
  ja: {
    [keys.CATEGORY]: "カテゴリー",
    [keys.INGREDIENTS]: "材料",
    [keys.HEALTH]: "健康",
    [keys.ALLERGY]: "アレルギー",
    [keys.SEARCH]: "検索",
    [keys.PRINT_VERSION]: "印刷バージョン",
    [keys.WEBSITE]: "ウェブサイト",
    [keys.PROTEIN]: "たんぱく質",
    [keys.GRAIN]: "穀物",
    [keys.VEGETABLE]: "野菜",
    [keys.FRUIT]: "果物",
    [keys.DAIRY]: "乳製品",
    [keys.FOOD]: "食べ物",
    [keys.ALCOHOLIC_BEVERAGE]: "アルコール飲料",
    [keys.NON_ALCOHOLIC_BEVERAGE]: "ノンアルコール飲料",
    [keys.OTHER]: "その他",
  },
};

/**
 * Translate words in app to other language.
 * @param {string} key
 * @returns
 */
export const translate = (key) => {
  const params = new URLSearchParams(window.location.search);
  const langCode = params.get("lang");
  const t = translations?.[langCode]?.[key];
  if (!t) console.error("Translation failed. No data exists.");
  return t;
};
